/* You can add global styles to this file, and also import other style files */
// @import '~ngx-toastr/toastr';
/* Core Grid CSS */
@import "ag-grid-community/styles/ag-grid.css";
/* Quartz Theme Specific CSS */
@import "ag-grid-community/styles/ag-theme-quartz.css";
@import "../node_modules/@fdjlss/ngx-org-chart/theming";

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
}

::-webkit-scrollbar-thumb {
  background-color: #00aaed !important;
  outline: 0px solid #fff;
  border-radius: 10px;
}
.forgotpassword-panel-form{
  width: 600px;
  height: auto;
}
.registration-panel-form {
  width: 100% !important;
  height: 80vh !important;
}
.registration-panel-form .mdc-dialog__surface::before {
  display: none;
}
.registration-panel-form .mat-mdc-dialog-container .mdc-dialog__surface {
  background-color: #fff;
  border-radius: 8px;
  overflow-y: visible;
}
.registration-panel-form .mdc-dialog__actions {
  display: block;
  padding: 40px 24px;
}
.mat-mdc-text-field-wrapper {
  width: 100%;
  /* background-color: #002e6b; */
}
.mdc-notched-outline__leading {
  border-color: #a7b1d1 !important;
}
.mdc-notched-outline__notch {
  border-color: #a7b1d1 !important;
}
.mdc-notched-outline__trailing {
  border-color: #a7b1d1 !important;
}
.mdc-text-field--outlined .mdc-notched-outline {
  height: 38px;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 5px;
  padding-bottom: 5px;
  min-height: 30px;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-text-field__input {
  color: #000 !important;
  font-size: 12px;
  font-weight: 500;
}
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__leading{
//   border-color: #F5821F!important;
// }
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__notch{
//   border-color: #F5821F!important;
// }
// .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__trailing{
//   border-color: #F5821F!important;
// }
.mdc-text-field--outlined:not(.mdc-text-field--disabled)
  .mdc-text-field__input {
  caret-color: #f5821f !important;
}
.mdc-text-field {
  border-radius: 4px !important;
}
.mat-mdc-option {
  color: #464646 !important;
    font-size: 13px !important;
}
div.mat-mdc-select-panel {
  background-color: #fff !important;
}
.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  background-color: #e9e9e9 !important;
  color: #fff !important;
}
.mdc-text-field__input::placeholder {
  font-size: 12px !important;
  color: #6e6e6e !important;
}
.mat-mdc-select-placeholder {
  font-size: 11px !important;
  color: #6e6e6e !important;
}
.mobile-code-area .mat-mdc-text-field-wrapper {
  background-color: transparent;
  /* border: 1px solid #002e6b; */
}
.mat-mdc-select-arrow svg {
  color: #6e6e6e;
}
.mobile-code-area .mat-mdc-select-arrow svg {
  color: #6e6e6e;
}
.language .mat-mdc-text-field-wrapper {
  background-color: transparent !important;
}
.language .mdc-notched-outline__leading {
  border: none;
}
.language .mdc-notched-outline__notch {
  border: none;
}
.language .mdc-notched-outline__trailing {
  border: none;
}
.language .mat-mdc-form-field-subscript-wrapper {
  display: none;
}
.language
  .mat-mdc-text-field-wrapper.mdc-text-field--outlined
  .mat-mdc-form-field-infix {
  min-height: 0px;
  padding: 0px;
}
.language {
  background-color: #00aaed;
  border-radius: 20px;
  padding: 10px;
  max-width: 180px !important;
}
.language .mat-mdc-select-placeholder {
  color: #fff !important;
}
.language img {
  max-height: 25px;
}
.hyperlink-panel {
  width: 40%;
}
.hyperlink-panel .mdc-dialog__title::before {
  height: inherit;
}
.hyperlink-panel .mat-mdc-dialog-container .mdc-dialog__title {
  line-height: inherit;
}
.hyperlink-panel .mdc-dialog .mdc-dialog__content {
  padding: 0px 24px 24px 24px;
}
::placeholder {
  color: #fff !important;
}
.profile img {
  width: 34px;
  height: 34px;
  margin-top: 15px;
}
.profile {
  position: relative;
  bottom: 108px;
  width: 85px;
  height: 85px;
  margin: auto;
    border: 1px solid #009fe360;
    background-color: #009fe3;
    border-radius: 50px;
    padding: 5px;
}

.registration-panel-form .mdc-dialog__title::before {
  height: inherit !important;
}
.registration-panel-form .mat-mdc-dialog-container .mdc-dialog__content {
  overflow-y: auto;
  height: calc(100% - 215px);
  // min-height: 350px;
}
.background {
  /* background-color: #002e6b; */
  height: auto;
  /* padding: 40px; */
}
.inner-background {
  background-image: url(./assets/images/bg.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: 0px 10px 20px 0px rgb(187 187 187 / 61%);
  border-radius: 8px;
  height: auto;
  padding: 30px 30px;
  border: 1px solid rgba(167, 177, 209, 0.2588235294);
  margin-bottom: 20px;
  margin-top: 40px;
}
.header {
  font-size: 16px;
  color: #002e6b;
  font-weight: 700;
}

.header span {
  color: #f5821f;
}
.icon-box {
  border: 1px solid #002e6b;
  background-color: #fff;
  border-radius: 8px;
  padding: 30px;
}
.icon-box img {
  max-height: 70px;
}
.blue-header {
  font-size: 13px;
  color: #6d6d6d;
  font-weight: 500;
  margin-bottom: 5px;
}

.mat-mdc-select-min-line {
  font-size: 11px;
    color: #6e6e6e;
}
.yellow-button {
  background-color: #f6821f;
  color: #fff;
  font-size: 14px;
  padding: 10px 40px;
  border: none;
  border-radius: 8px;
  /* box-shadow: 0px 5px 10px 2px #00aaed75; */
  cursor: pointer;
}
.blue-button {
  background-color: #f58524;
  color: #fff;
  font-size: 13px;
  padding: 10px 30px;
  border: none;
  border-radius: 8px;
  box-shadow: 0px 3px 11px -4px #00000094 !important;
  cursor: pointer;
}
.blue-button:focus-visible {
  outline: inherit !important;
}
.sky-blue-header {
  color: #00aaed;
  font-size: 16px;
  font-weight: 700;
}
.sky-blue-header h3 {
  font-size: 20px;
  color: #009fe3;
  font-weight: 500;
}
.mat-mdc-form-field:not(.mat-form-field-no-animations) .mdc-floating-label {
  border-radius: 4px;
}
.mat-mdc-form-field .mat-mdc-floating-label.mdc-floating-label {
  color: #a7b1d1 !important;
  /* background-color: #fff; */
  /* padding: 10px; */
  font-size: 12px;
  font-weight: 500;
  top: 20px;
}
.mdc-form-field > label {
  font-size: 14px;
  color: #242424;
  font-weight: 500;
}
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:not(:checked)
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #f5821f !important;
}
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled:checked
  + .mdc-radio__background
  .mdc-radio__outer-circle {
  border-color: #00aaed !important;
}
.mat-mdc-radio-button
  .mdc-radio
  .mdc-radio__native-control:enabled
  + .mdc-radio__background
  .mdc-radio__inner-circle {
  border-color: #00aaed !important;
}
.footer-img img {
  width: 100%;
}
a {
  color: #f58220 !important;
  font-weight: 700;
}
.fixed-header {
  position: sticky;
  top: 0px;
  z-index: 999;
}
.top-nav {
  padding: 10px 40px;
  background: #fff;
  box-shadow: 0 0 8px 0px #a3a3a37a;
}
.heading-banner {
  background: #00aaed;
  padding: 8px;
  font-size: 20px;
  font-weight: 500;
  color: #fff;
}
.heading-banner span {
  color: #f58220;
}
.fixed-header img {
  width: 110px;
}
.fixed-header .mat-mdc-icon-button.mat-mdc-button-base {
  color: #009fe3;
}
/* .body-text {
  font-size: 16px;
  color: #fff;
  font-weight: 400;
} */
.body-text {
  color: #002e6b;
  /* text-align: center; */
  font-weight: 500;
}
.divider {
  border-bottom: 1px solid #002e6b;
}
.mat-mdc-icon-button img {
  height: 20px !important;
}
.footer {
  background-color: #002e6b;
  color: #fff;
  font-size: 12px;
  padding: 10px;
}
.subscribe-button {
  position: fixed;
  z-index: 999;
  top: 40%;
  right: 0;
  background-image: linear-gradient(to right, #d40303, #690101);
  color: #fff;
  font-weight: 500;
  padding: 10px;
  border-radius: 8px;
  text-orientation: upright;
  writing-mode: vertical-lr;
}
.subscribe-button span {
  text-orientation: unset;
  writing-mode: horizontal-tb;
  background-color: #fff;
  border-radius: 4px;
  color: #b80303;
  padding: 5px;
  font-weight: 700;
}
.bee-facts-area {
  background-color: #002e6b;
  color: #fff;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}
.bee-facts-icon-area {
  background-color: #00aaed;
  border-radius: 8px;
}
.bee-facts-text-area {
  padding: 10px;
}
.main-yellow-header {
  font-size: 20px;
  color: #009fe3;
  font-weight: 500;
}
.main-yellow-headers {
  position: relative;
  top: -50px;
  font-size: 23px;
  color: #009fe3;
  font-weight: 500;
}
.mat-mdc-progress-spinner {
  height: 20px !important;
  width: 20px !important;
}
.mat-mdc-progress-spinner circle,
.mat-mdc-spinner circle {
  stroke: #ffffff !important;
}
table tr {
  background-color: #00aaed;
  color: #fff;
}
table tr th {
  padding: 10px;
  font-weight: 500;
  border-right: 1px solid #fff;
}
table tbody tr {
  border-bottom: 1px solid #a7b1d1;
  background-color: transparent;
  color: #000;
}
table tbody tr td {
  padding: 10px;
}
table {
  width: 100%;
}
table .mat-icon {
  color: #a7b1d1;
}
.box {
  padding: 10px 5px;
  border-radius: 8px;
  color: #009fe3;
  font-weight: 500;
  font-size: 13px;
  text-align: center;
  border: 1px solid #00aaed54;
  cursor: pointer;
}
.table-scroll {
  overflow: auto;
  height: 80vh;
}


.navShort{
  max-width: 5% !important;
  transition: all 0.5s ease-in-out;
}

.navFull{
  max-width: 16.5% !important;
  transition: all 0.5s ease-in-out;
}
.formText {
  padding: 20px;
}

@media (min-width: 768px) and (max-width: 1280px) {
  table {
    width: 1067px;
  }
}
@media (min-width: 300px) and (max-width: 599px) {
  .inner-background {
    padding: 40px 40px;
    
  }
}

@media (min-width: 320px) and (max-width: 767px) {
  .no-display {
    width: 40% !important;
    z-index: 99;
}

.main-menu-title {
  padding: 10px 10px 10px 10px !important;
 
}
}

// ag grid scroll
@media (max-width: 1280px) {
  .tableScroll .ag-header{
    width: 1236.29px;
  }
  .tableScroll .ag-body {
    width: 1236.29px;
  }
  .tableScroll .ag-root.ag-layout-normal {
    overflow-x: auto;
  }
  .tableScrollBar {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    width: 1280px;
}
}
// big screen media query {
  @media (min-width: 1440px) and (max-width: 1920px) {
    
  }
  // big screen laptop 
  
  @media (min-width: 1024px) and (max-width: 1440px){
    .admin-login-page {
      width: 80% !important;
      padding: 125px 30px !important;
    }
    .admin-login-pages {
      width: 88% !important;
      padding: 68px 35px !important;
    }
    .leftbgImg img {
      width: 455px !important;
    }
    .rightText {
      padding: 58px 40px 58px 40px !important;
    }
    .profile img {
      width: 45px !important;
      height: 45px !important;
  }
  }
   
  // medium screen laptop 
  @media (min-width: 767px) and (max-width: 1024px) {
  .admin-login-page {
    width: 100% !important;
    padding: 130px 0 !important;
  }
  .admin-login-pages {
    width: 100% !important;
  }
  .admin-logins {
    padding: 20px 30px !important;
  }
  .otp-input input {
    width: 45px !important;
    height: 40px !important;
  }
  .rightText {
    padding: 60px 30px 60px 30px;
}
  }
  
    // tablet screen
    @media (min-width: 520px) and (max-width: 768px){
      .navShort {
        max-width: 5% !important;
        transition: all 0.5s ease-in-out;
        position: relative !important;
        z-index: 999 !important;
    }
    .admin-login-pages {
      width: 90% !important;
    }
    .no-display {
      width: 54px !important;
    }
    .otp-input input {
      width: 36px !important;
      height: 32px !important;
  }
    
    .admin-login-page {
      width: 96% !important;
      padding: 185px 0 !important;
    }
    
    .admin-logins {
      padding: 0px 20px !important;
    }
    .leftbgImg img {
      width: 100% !important;
    }
    .rightText {
      padding: 15px 20px 15px 20px !important;
    }
    .main-yellow-header {
      font-size: 17px !important;
      margin-bottom: 13px !important;
    }
    .main-yellow-headers {
      font-size: 21px !important;
    }
    .otpContainer p {
      margin-bottom: 20px !important;
    }
    .otpContainer h1 {
      font-size: 18px !important;
    }
    .mainBackground {
      padding: 30px 25px;
    }
    .active_tab_1::after {
      content: "";
      display: none !important;
  }
  .active_tab_4::after {
    content: "";
    display: none !important;
  }
  .active_tab_2::after {
    content: "";
    display: none !important;
  }
  .active_tab_3::after {
    content: "";
    display: none !important;
  }
  .active_tab_5::after {
    content: "";
   display: none !important;
  }
  .display {
    width: 22.4% !important;
    transition: all 0.5s ease-in-out;
    z-index: 99 !important;
}
    
    }
    
  // mobile screen
  @media (min-width: 320px) and (max-width: 767px) {
    .admin-logins {
      padding: 25px 47px !important;
    }
    .admin-login-page {
      width: 100% !important;
      padding: 0px 0 !important;
    }
    .leftbgImg img {
      width: 100% !important;
      border-bottom-left-radius: 0px !important;
      border-top-right-radius: 4px !important;
    }
   .show-menu{
    display: none !important;
   }
   .hide-menu{
    display: block !important;
   }
   .show-sm{
    display: none !important;
    background: #2c3e50;
    transition: all 0.3s ease;
}

.hide-sm{
    display: block !important;
    background: #2c3e50;
    transition: all 0.3s ease;
}

.no-display .mat-icon {
  /* display: none; */
  transform: rotate(360deg) !important;
}
.m-menu-active .arrow-icon{
  background-color: #00aaed;
  color: #fff;
  transform: rotate(90deg) !important;
  transition: all 0.1s ease;

}
    .otp-input input {
      width: 38px !important;
      }
    .main-yellow-header {
      font-size: 20px !important;
  }
  .rightText {
    padding: 25px 20px 25px 20px !important;
  }
  .top-nav {
    padding: 10px 10px !important;
  }
  .mainBackground {
    padding: 60px 20px !important;
  }
  .admin-login-pages {
    width: 100% !important;
  }
  .active_tab_1::after {
    content: "";
    display: none !important;
}
.active_tab_4::after {
  content: "";
  display: none !important;
}
.active_tab_2::after {
  content: "";
  display: none !important;
}
.active_tab_3::after {
  content: "";
  display: none !important;
}
.active_tab_5::after {
  content: "";
 display: none !important;
}
.navFull {
  max-width: 12.4% !important;
  transition: all 0.5s ease-in-out;
}
.display {
  width: 12.4% !important;
  transition: all 0.5s ease-in-out;
}
.sub-menu-title {
  padding: 10px 5px 10px 9px !important;
}
.fixed-header img {
  width: 90px !important;
}
.welcomeText {
  font-size: 11px !important;
  width: 105px !important;
  line-height: 15px;
}
.heading_top {
  padding: 5px 5px !important;
}
.page-title h1 {
  font-size: 15px !important;
}
.page-title p {
  font-size: 10px !important;
}
.add_button button {
  font-size: 10px !important;
  padding: 5px 7px !important;
}
.blue-button {
  padding: 8px 12px !important;
}
.formText {
  padding: 20px 10px !important;
}
.heading-banner {
  padding: 4px 0px !important;
  font-size: 14px !important;
  line-height: 17px !important;
}

.asess-title {
  color: #00aaed;
  padding: 4px 0 !important;
  line-height: 17px !important;
  font-size: 14px !important;
  padding-left: 0px !important;
  margin-right: 5px !important;
}
.custom-dialog-container {
	width: 80vw !important;
	height: auto !important;
 }
  }
  
  @media (min-width: 310px) and (max-width: 375px){
    .leftbgImg img {
      width: 100% !important;
    }
    .admin-logins {
      padding: 25px 35px !important;
  }
  .main-yellow-header {
    font-size: 18px !important;
}
.otp-input input {
  width: 29px !important;
  height: 30px !important;
  margin: 0 5px !important;
  }
  .heading-banner {
    padding: 4px 0px !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }
}
  

.backBtn {
  background-color: #f58220;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 0px 34px;
    height: 35px;
}

.Tabs-button {
  padding: 10px 15px;
  border-radius: 8px;
  color: #00aaed;
  font-weight: 700;
  font-size: 16px;
  text-align: center;
  background-color: transparent;
  border: 2px solid #00aaed;
}
.Tabs-button-active {
  background-color: #f58220;
  border: none;
  color: #fff;
}
.mdc-checkbox
  .mdc-checkbox__native-control:enabled:checked
  ~ .mdc-checkbox__background {
  border-color: #f5821f !important;
  background-color: #f5821f !important;
}
.mat-calendar-table-header tr {
  background-color: transparent;
}
.mat-calendar-body-selected {
  background-color: #f5821f !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #fff !important;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
  color: #2e2e2e !important;
}
.side-menu {
  background-color: #fff;
  padding: 6px 6px;
  box-shadow: 5px 0px 30px 0px #88888836;
  height: calc(100vh - 61px);
  position: fixed;
}
.side-menu-option {
  padding: 15px 8px 15px 16px;
  font-size: 14px;
  color: #000;
  cursor: pointer;
  border-bottom: 1px solid #c5e0f9;
}
.side-menu-option img {
  max-height: 20px;
  margin-right: 10px;
  background-color: #fff;
  border-radius: 2px;
}
.active {
  background-color: #002e6b;
  color: white;
  border-radius: 4px;
}
.display {
  width: 16.4%;
  transition: all 0.5s ease-in-out;
}
.no-display {
  width: 75px;
  transition: all 0.5s ease-in-out;
  /* transition-delay: 0.8s; */
  /* padding: inherit; */
}
.no-display .side-menu-option span {
  display: none;
}

.no-display .mat-icon {
  /* display: none; */
  transform: rotate(180deg);
}

.menu-icon .mat-icon {
  position: absolute;
  color: #002e6b;
}
.display-icon {
  display: none;
}
.no-display-icon {
  display: block;
}
.side-menu .mat-icon {
  color: #f5821f;
  font-size: 30px;
}
.mat-expansion-panel-header {
  padding: 0px !important;
}
.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
}
.mat-expansion-panel-body {
  padding: 0 0px 16px 20px !important;
}
/* .mat-expansion-panel-header-title{
  border-bottom: 1px solid #c5e0f9;
} */
.mat-expansion-panel .mat-expansion-panel-body .side-menu-option {
  border-bottom: 1px solid #c5e0f9 !important;
}
.mat-expansion-panel-header.mat-expanded {
  height: inherit !important;
  border-bottom: 1px solid #c5e0f9;
}
.mat-expansion-panel .side-menu-option {
  border-bottom: none !important;
}
.mat-expansion-indicator {
  width: 10%;
  text-align: center;
}
.mat-content {
  width: 90%;
}
.active {
  background-color: #002e6b;
  color: white !important;
  border-radius: 4px;
}
.active-child {
  color: #f5821f !important;
  border-radius: 4px;
}
.no-display .mat-expansion-indicator {
  width: 40%;
}
.no-display .mat-content {
  width: 60%;
}
.mat-expansion-panel-header.active .mat-expanded:hover {
  background: #002e6b !important;
  color: white !important;
}
.mat-expansion-panel .active span {
  color: #fff !important;
}
.mat-expansion-panel .active .mat-expansion-indicator::after {
  color: #fff !important;
}
/* .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: rgb(0 46 107)!important;
} */
.mat-expansion-panel-header.mat-expanded:hover {
  background: #002e6b;
}
.mat-expansion-panel .active:hover {
  background: #002e6b !important;
}
.mat-expansion-panel-header.mat-expanded:focus {
  background: #002e6b !important;
}
.mat-expansion-panel-header.mat-expanded:focus span {
  color: #fff !important;
}
.mat-expansion-panel-header.mat-expanded:focus .mat-expansion-indicator::after {
  color: #fff !important;
}
.ag-header {
  background-color: #00aaed;
}
.ag-header-cell-text {
  color: #fff;
  font-size: 13px;
}
.ag-center-cols-viewport {
  font-size: 12px;
}
.ngx-org-image {
  display: none;
}
.ngx-org-box {
  max-width: 100% !important;
  // height: 33px !important;
  // line-height: 4px !important;
  border-radius: 5px !important;
  width: 100%;
}
.ngx-org-box {
  font-size: 13px;
  padding: 0px 20px;
}
.t-c-text h2 {
  font-weight: 700;
  font-size: 14px;
  color: #002e6b;
}

.customClass {
  z-index: 998 !important;
}

.customClass + * .cdk-overlay-pane {
  z-index: 998 !important;
}
.customClass + .cdk-overlay-connected-position-bounding-box {
  z-index: 998 !important;
}

.cdk-overlay-container:has(.customClass) {
  z-index: 998 !important;
}
.customClass-header {
  z-index: 1000 !important;
}

.customClass-header + * .cdk-overlay-pane {
  z-index: 1000 !important;
}
.customClass-header + .cdk-overlay-connected-position-bounding-box {
  z-index: 1000 !important;
}

.cdk-overlay-container:has(.customClass-header) {
  z-index: 1000 !important;
}

.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled)
  .mdc-list-item__primary-text {
  color: #000 !important;
}

.mat-mdc-option.mat-mdc-option-active.mdc-list-item {
  color: #272727 !important;
}
.mdc-text-field--invalid {
  border: 1px solid red;
}
.mdc-text-field--invalid .mdc-notched-outline__leading {
  border-color: transparent !important;
}
.mdc-text-field--invalid .mdc-notched-outline__notch {
  border-color: transparent !important;
}
.mdc-text-field--invalid .mdc-notched-outline__trailing {
  border-color: transparent !important;
}

.assessCount:hover {
  border-left: 4px solid #1070ee !important;
  border-right: 4px solid #1070ee !important;
  border-radius: 5px !important;
  box-sizing: border-box !important;
  font-size: 18px !important;
  cursor: pointer !important;
  transition: all 0.1s ease-in-out !important;
}
.mat-mdc-form-field-error {
  line-height: 10px;
  font-size: 10px !important;
  display: flex !important;
    padding-top: 3px;
}



.editButton {
  background-color: #00bfff;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  line-height: 23px;
  margin-right: 8px;
  text-align: center;
}

.downlodButton {
  background-color: #8cc349;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  line-height: 23px;
  margin-right: 8px;
  text-align: center;
}
.updownButton {
  background-color: #f5821f;
  border-radius: 3px;
  color: #fff;
  font-size: 15px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  line-height: 23px;
  margin-right: 8px;
  text-align: center;
}

.infoButton i {
  background-color: #d32001;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  line-height: 23px;
  margin-right: 8px;
  text-align: center;
}
.handsButton i{
  background-color: #009688;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  line-height: 23px;
  margin-right: 8px;
  text-align: center;
}
.filetextButton i {
  background-color: #14ced4;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  line-height: 23px;
  margin-right: 8px;
  text-align: center;
}

.viewButton {
  background-color: #9a9a9a;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  margin-right: 8px;
  line-height: 20px;
  text-align: center;
}
.sendButton {
  background-color: #2196f3;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  margin-right: 8px;
  line-height: 23px;
  text-align: center;
}

.deleteButton {
  background-color: #ff3a6e;
  border-radius: 3px;
  color: #fff;
  font-size: 12px;
  height: 22px !important;
  width: 22px !important;
  margin-top: 9px;
  margin-right: 8px;
  line-height: 23px;
  text-align: center;
}

app-action {
  display: flex !important;
}

.page-title h1 {
	font-weight: 500;
	color: #00bfff;
	font-size: 18px;
	margin-bottom: 0;
	line-height: 20px;
	font-family: Roboto, sans-serif;
}
.heading_top {
	width: 100%;
	justify-content: space-between;
	display: flex;
	align-items: center;
	border-bottom: 1px solid #e7e7e7;
	padding: 5px 15px;
	background-color: #fff;
}
.page-title {
	float: left;
}
.page-title p {
	font-weight: 400;
	color: #212b36;
	margin-top: 0;
	font-size: 13px;
	margin-bottom: 0;
}
.page-title p span {
	color: #212b36;
	cursor: pointer;
	font-weight: 600;
}
.add_button button {
	background-color: #f5821f;
	font-size: 12px;
	font-weight: 400;
	color: #fff;
	border-radius: 6px;
  border: 1px solid #f5821f;
  padding: 7px 16px;
  box-shadow: #d0d0d0 0 3px 8px;
}
.add_button button:last-child {
	margin-right: 0;
}
.uploadButton {
  width: 113px;
    height: 36px;
    line-height: 20px;
    margin-right: 10px;
    margin-left: 10px;
    background: rgb(3, 171, 237);
}

.m_chart_con{
  width: 99vw !important;
  height: 100% !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}